import { Comment, ThumbUp, Reply, Facebook } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
// import { findAllByPlaceholderText } from "@testing-library/react";
import React from "react";
import { parseURL } from "../../../common";
import PostDateElement from "../element/PostDateElement";
import PostImageElement from "../element/PostImageElement";
import PostMetaElement from "../element/PostMetaElement";
import PostTextElement from "../element/PostTextElement";
import PostTitleElement from "../element/PostTitleElement";
import GroupIcon from '@mui/icons-material/Group';

function FacebookGroupPostCard({post, padding}){
    
    let text = post.post_text;
    text = parseURL(text);
    
    
    return <>

        <Box p={padding}>
            
            <Typography sx={{fontSize:"small", fontWeight:"bold", color:"#999999"}}>
                <>
                <GroupIcon sx={{fontSize:"14px",position:"relative",top:"2px",marginRight:"2px"}} />
                <a href={"http://facebook.com/groups/" + post.fb_group_id} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#999999' }}>{post.fb_group_name}</a>
                </>
            </Typography>
            <Typography sx={{fontSize:"small", fontWeight:"bold", color:"#666666"}}>
                {/* {post.fb_group_name} */}
                <>
                <a href={"http://facebook.com/profile.php?id=" + post.user_id} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#666666' }}>{post.user_screenname}</a>
                </>
            </Typography>
        <Divider />
    
        </Box>

        <PostImageElement post={post} />

        <Box p={padding}>
            {/* THIS BREAKS THINGS */}
            <PostTextElement post={post} text={text} truncate={200} /> 
            <PostDateElement post={post} />
        </Box>
        <Divider />
        <Box p={padding} display="flex" alignItems={"center"} justifyContent="space-between">
            <Box display="flex" alignItems={"center"} mr={1}>
                <ThumbUp sx={{marginRight: 0.5, fontSize:14}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_likes}</span>

                <Comment sx={{ fontSize: 14, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_comments}</span>

                <Reply sx={{ fontSize: 14, marginRight: 0.5, marginLeft: 2}} fontSize="small"/>
                <span style={{fontSize: 12}}>{post.fb_shares}</span>
            </Box>
            <Box>
                <Facebook sx={{fontSize:20, color: '#4267B2', margin:0}}/>
                <GroupIcon sx={{fontSize:20, color: '#4267B2', margin:0}}/>
            </Box>
        </Box>
        <Divider />
        <Box p={padding}>
            <PostMetaElement post={post} />
        </Box>
    </>
}
export default FacebookGroupPostCard;