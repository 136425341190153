const platforms = {
    "wn": 'News',
    "wf": 'Forums',
    "wb": 'Blogs',
    "fb": 'Facebook',
    "fbgr": 'Facebook Group',
    "ig": 'Instagram',
    "tw": 'X',
    "in": 'Linkedin',
    "rd": 'Reddit',
    "yt": 'Youtube',
    "tt": 'TikTok',
};

const getPlatformLabel = (id) => {
    const label = platforms[id];
    return label ? label.replace("--","").trim() : ""
}

export default platforms;
export {getPlatformLabel};