import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import priorities from "../../../config/priorities";
import sentiments from "../../../config/sentiments";
import languages from "../../../config/languages";
import { translatePost } from "../../../features/post";

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import TranslateIcon from '@mui/icons-material/Translate';

function PostMetaElement({post}){
    const project = useSelector(state => state.project.single);
    const tags = project.tags;
    const dispatch = useDispatch();

    const metaIconStyle = {color:"#999999",fontSize:18,position:"relative",top:"4px",marginRight:"5px"};
    
    return <>
    <Typography variant="body2">

    {
        post.sentiment !== 'null' && <>
        <SentimentSatisfiedOutlinedIcon alt="Sentiment" title="Sentiment" style={metaIconStyle}/>
    <b>{sentiments[post.sentiment]}</b><br/>
    </>
    }
    
    {
    post.lang && <>
    <LanguageOutlinedIcon style={metaIconStyle}/>
    <b>{languages[post.lang]}</b>
    {post.lang!=="en" && <>
    &nbsp;<TranslateIcon onClick={() => {
        dispatch(translatePost({
            uuid: post.uuid,
            projectId: project.id
        }))
    }} style={{...metaIconStyle, color: "#666666", cursor: "pointer"}}/>
    </>}
    <br/>
    </>
    }

    <PriorityHighOutlinedIcon style={metaIconStyle}/>
    <b>{priorities[post.priority]}</b><br/>
    
    {
        post.geo_countrycode && <>
        <FmdGoodOutlinedIcon style={metaIconStyle}/>
        <b>{post.geo_name}</b>, {post.geo_countrycode.toUpperCase()}<br/>
        {/* <a rel="noreferrer" target="_blank" href={'https://www.openstreetmap.org/#map=12/'+post.location.lat+'/'+post.location.lon}>{post.geo_name}</a>, {post.geo_countrycode.toUpperCase()}<br/> */}
        </>
    }
    
{
    post.tags && post.tags.length > 0 && <>
    <SellOutlinedIcon style={metaIconStyle}/> <b>{post.tags && post.tags.length > 0 ? post.tags.map(tag => {
        return tags.find(t => t.id === parseInt(tag));
    }).filter(t => !!t).map(t => t.label).join(", ") : "--"}</b><br/>
    </>
}   
    </Typography>
    </>
}
export default PostMetaElement;